import { Trans } from '@lingui/macro';
import {
  Box,
  Grid,
  Link as MuiLink,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import ArrowRightIcon from 'src/assets/icons/arrow-right.svg';
import FailIcon from 'src/assets/icons/fail.svg?url';
import SuccessIcon from 'src/assets/icons/success.svg?url';
import { MyLink } from 'src/components/core/mine/my-link';
import { useSocketEventFn } from 'src/hooks/socket/use-socket-fn';
import { useSyncTimer } from 'src/hooks/use-timer';
import {
  cancelDialogEmailExpiredAtom,
  emailVerifiedAtom,
  emailVerifyExpireAtAtom,
  kycAtom,
  profileAtom,
} from 'src/store/auth';
import { depositedAtom } from 'src/store/wallet';
import { Paths } from 'src/utils/constants/path';
import { SocketEvent } from 'src/utils/constants/socket-event';
import { showIntercom } from 'src/utils/helpers/intercom';
import { trackDepositInitiated } from 'src/utils/helpers/rudderstack';
import { KYCStatusKey } from 'src/utils/types/auth';

enum StepStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export const Stepper: React.FCC = () => {
  const [emailVerified, setEmailVerified] = useAtom(emailVerifiedAtom);
  const [profile, fetchProfile] = useAtom(profileAtom);
  const email = profile?.email;
  const userId = profile?.id;
  const deposited = useAtomValue(depositedAtom);
  const kyc = useAtomValue(kycAtom);
  const identityVerified = kyc === KYCStatusKey.KYC_DONE;
  const cancelDialogEmailExpired = useAtomValue(cancelDialogEmailExpiredAtom);
  const setEmailVerifyExpireAt = useSetAtom(emailVerifyExpireAtAtom);
  const setCancelDialogEmailExpired = useSetAtom(cancelDialogEmailExpiredAtom);
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState<boolean>(false);

  useSocketEventFn(SocketEvent.VERIFY_EMAIL_SUCCESS, () => {
    setEmailVerified(true);
  });

  useSocketEventFn(SocketEvent.PROFILE_VERIFY_SUCCESS, () => {
    fetchProfile();
  });

  const handleResendVerifyEmail = async () => {
    try {
      const accInfo = await fetchProfile();

      if (
        accInfo?.emailVerifyExpireAt &&
        moment().isBefore(accInfo?.emailVerifyExpireAt)
      )
        return;

      setSending(true);
      await setEmailVerifyExpireAt();
      enqueueSnackbar(
        <Trans id="resendEmail.success">
          Verification email sent to {email}
        </Trans>,
        {
          variant: 'success',
        },
      );
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setSending(false);
    }
  };

  return (
    <Paper
      sx={{
        boxShadow: '0 4px 4px rgba(0, 0, 0, 0.16)',
        padding: 3,
        mb: 3,
      }}>
      <Box marginBottom={3}>
        <Typography variant="h2" sx={{ fontSize: 24, fontWeight: 700 }}>
          <Trans id="home.welcome.setup.title">Welcome to UBET!</Trans>
        </Typography>
        <Typography variant="h2" mt={1} sx={{ fontSize: 18, fontWeight: 700 }}>
          <Trans id="home.welcome.setup.sub_title">
            Let’s get you all set up.
          </Trans>
        </Typography>
      </Box>

      <Grid container spacing={{ xs: 3, md: 4 }}>
        <Grid item md={6} sm={12} xs={12}>
          <StepperItem
            step={emailVerified ? StepStatus.SUCCESS : 1}
            primary={
              emailVerified ? (
                <Trans id="home.welcome.setup.step_1.primary_1">
                  Email verified
                </Trans>
              ) : cancelDialogEmailExpired ? (
                <Trans id="activation.warning.title">
                  Verification email has expired
                </Trans>
              ) : (
                <Trans id="home.welcome.setup.step_1.primary_2">
                  Verify your email.
                </Trans>
              )
            }
            secondary={
              emailVerified ? null : cancelDialogEmailExpired ? (
                <Trans id="home.welcome.setup.step_1.secondary_2">
                  Please{' '}
                  <MuiLink
                    onClick={async () => {
                      if (sending) return;
                      await handleResendVerifyEmail();
                      setCancelDialogEmailExpired(false);
                    }}
                    underline="always"
                    role="button"
                    component="span"
                    color="custom.content.1"
                    sx={{
                      cursor: 'pointer',
                      ...(sending && {
                        pointerEvents: 'none',
                      }),
                    }}>
                    resend the verification email
                  </MuiLink>{' '}
                  to continue.
                </Trans>
              ) : (
                <Trans id="home.welcome.setup.step_1.secondary">
                  Please click on the link that has been sent to {email}
                </Trans>
              )
            }
            description={
              !emailVerified && (
                <VerifyEmailTimer
                  sending={sending}
                  handleResendVerifyEmail={handleResendVerifyEmail}
                />
              )
            }
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <StepperItem
            step={deposited ? StepStatus.SUCCESS : 2}
            primary={
              deposited ? (
                <Trans id="home.welcome.setup.step_2.primary_1">
                  Funds deposited
                </Trans>
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  component={MyLink}
                  color="primary"
                  onClick={() =>
                    trackDepositInitiated({ userId, source: 'onboarding' })
                  }
                  href={Paths.DepositCrypto}>
                  <Trans id="home.welcome.setup.step_2.primary_2">Crypto</Trans>
                  <ArrowRightIcon />
                </Stack>
              )
            }
            description={
              deposited ? (
                !identityVerified && (
                  <Typography color="custom.content.2">
                    <Trans id="home.welcome.setup.step_2.description">
                      It may take up to an hour for funds to be visible.{' '}
                    </Trans>
                  </Typography>
                )
              ) : (
                <Typography color="custom.content.2">
                  <Trans id="home.welcome.setup.step_2.description_2">
                    No crypto? No problem!{' '}
                    <MuiLink
                      href={Paths.DepositPurchase}
                      underline="always"
                      role="button"
                      component={MyLink}
                      sx={{ cursor: 'pointer' }}>
                      Purchase quickly through our secure payment gateway
                    </MuiLink>
                  </Trans>
                  .
                </Typography>
              )
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

interface StepperItemProps {
  step: number | StepStatus;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  description?: React.ReactNode;
}

const StepperItem: React.FCC<StepperItemProps> = (props) => {
  const { step, primary, secondary, description } = props;

  const success = step === StepStatus.SUCCESS;
  const prevStepNotDone = _.isNumber(step);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
        }}>
        <Box
          sx={{
            flexShrink: 0,
            width: 34,
            height: 34,
            marginRight: 1.5,
            borderRadius: '50%',
            border: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            userSelect: 'none',
            ...(!prevStepNotDone && {
              color: 'transparent',
              border: 'none',
              backgroundImage: `url(${
                success ? SuccessIcon.src : FailIcon.src
              })`,
            }),
          }}>
          {step}
        </Box>
        <Box>
          <Typography
            fontWeight={500}
            sx={{
              fontSize: 16,
              lineHeight: '34px',
              wordWrap: 'break-word',
            }}>
            {primary}
          </Typography>

          {secondary && (
            <Box sx={{ wordWrap: 'break-word', mb: ' 12px' }}>{secondary}</Box>
          )}

          {description && (
            <Box sx={{ wordWrap: 'break-word' }}>{description}</Box>
          )}
        </Box>
      </Box>
    </>
  );
};

interface VerifyEmailTimerProps {
  sending: boolean;
  handleResendVerifyEmail: () => void;
}

const VerifyEmailTimer: React.FCC<VerifyEmailTimerProps> = ({
  sending,
  handleResendVerifyEmail,
}) => {
  const emailVerifyExpireAt = useAtomValue(emailVerifyExpireAtAtom);
  const duration = useSyncTimer(emailVerifyExpireAt);

  const second = duration.asSeconds();

  const cancelDialogEmailExpired = useAtomValue(cancelDialogEmailExpiredAtom);

  const canRequestVerify = second <= 0;

  const remain = moment(second * 1000).format('mm:ss');

  return (
    <>
      {!cancelDialogEmailExpired &&
        (!canRequestVerify ? (
          <Typography color="custom.content.2">
            <Trans id="home.welcome.setup.step_1.resend.description">
              Resend verification email in {remain}
            </Trans>
          </Typography>
        ) : (
          <MuiLink
            onClick={() => {
              if (sending) return;
              handleResendVerifyEmail();
            }}
            role="button"
            component="div"
            underline="always"
            sx={{
              cursor: 'pointer',
              ...(sending && {
                pointerEvents: 'none',
              }),
            }}>
            <Trans id="home.welcome.setup.step_1.resend.submit">
              Resend verification email
            </Trans>
          </MuiLink>
        ))}

      <Typography color="custom.content.2" marginTop={1}>
        <Trans id="home.welcome.setup.step_1.description">
          No email? Please check your spam folder or{' '}
          <MuiLink
            onClick={showIntercom}
            underline="always"
            role="button"
            component="span"
            sx={{ cursor: 'pointer' }}>
            get in touch
          </MuiLink>
          .
        </Trans>
      </Typography>
    </>
  );
};
