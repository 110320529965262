/* eslint-disable lingui/no-single-variables-to-translate */
import { Trans } from '@lingui/macro';
import { Box, NoSsr } from '@mui/material';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';

export const TournamentTimer: React.FCC<{
  status?: TOUR_TIME_STATUS;
  date?: moment.Duration;
}> = ({ date, status = TOUR_TIME_STATUS.UPCOMING }) => {
  if (status === TOUR_TIME_STATUS.ENDED) return null;

  const days = date?.days() ?? 0;
  const hours = date?.hours() ?? 0;
  const minutes = date?.minutes() ?? 0;

  return (
    <NoSsr>
      <Box
        sx={{
          color: 'custom.content.2',
          fontWeight: 500,
          textAlign: 'center',
          '& .in': { mr: 1 },
          '& .number': {
            color: 'custom.content.1',
            width: '2ch',
            display: 'inline-block',
          },
        }}>
        <Box sx={{ display: 'inline-block' }} className="in">
          {status === TOUR_TIME_STATUS.UPCOMING ? (
            <Trans id="tournaments.starting_in">Starting in:</Trans>
          ) : (
            <Trans id="tournaments.ends_in">Ends in:</Trans>
          )}
        </Box>
        <Box sx={{ display: 'inline-block' }}>
          <Trans
            id="tournaments.count_down"
            comment="<days> : <hours> : <minutes>">
            <span className="number">{days}</span>
            {` d : `}
            <span className="number">{hours}</span>
            {` h : `}
            <span className="number">{minutes}</span>
            {` m`}
          </Trans>
        </Box>
      </Box>
    </NoSsr>
  );
};
